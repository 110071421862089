// kendo custom methods
$(function kendoCustomLegacyModule() {
    "use strict";

    /**
     * Generates proper object for usage with confirmationPopup method, with default values.
     *
     * @returns {Object} Proper object with default values.
     */
    var _getDefaultOptionsForConfirmationPopup = function () {
        return {
            /**
             * {boolean} Whether to animate the popup window. Default: true
             */
            animate: true,

            /**
             * {string} CSS width. Default: "300px"
             */
            width: "300px",

            /**
             * {string} Title the popup windows should have. Set to false value to omit title completely. Default: Resources.ConfirmAction
             */
            title: Resources.ConfirmAction,

            /**
             * {boolean} What does closing the window by cross mean? Default: false
             * Use true with window that has for example only "OK" button and no "Cancel" button.
             */
            resultOnClose: false,

            /**
             * {string} Suffix for icon in popup. Will be appended to "wcicon-s wcicon-" class string. Default: null
             * When null, the icon will be omitted completely.
             */
            iconClassSuffix: null,

            /**
             * {string} Message HTML to use as contents injected into .popup-message element.
             * When value evaluetes to false, content is expected to be filled from template.
             */
            simpleMessage: null
        };
    };

    /**
     * Provides confirmation popup dialog promise.
     * Content is expected by CSS to have either two buttons (.confirm-yes + .confirm-no) or (.confirm-ok + .confirm-cancel) or single OK button (.confirm-ok).
     * Also events are bound to those buttons for the dialog to actually do something.
     *
     * @param {jQueryObject} content Stuff to use as content for popup.
     * @param {Object} options Options for popup. Use getDefaultOptionsForConfirmationPopup method to get proper object with default values.
     * @return {object} promise
     */
    var _confirmationPopup = function (content, options) {
        if (typeof content === "undefined") { console.log("Invalid usage of confirmationPopup method!"); return false; } // eslint-disable-line no-console
        if (typeof options === "undefined") { options = _getDefaultOptionsForConfirmationPopup(); }

        var dfd = new jQuery.Deferred();
        var answer = options.resultOnClose;

        // Set-up optional icon
        if (options.iconClassSuffix === null) {
            content.find(".popup-icon").remove();
        } else {
            content.find(".popup-icon").append($("<span class=\"wcicon-s wcicon-" + options.iconClassSuffix + "\"></span>"));
        }

        // Set-up message if given
        if (options.simpleMessage) {
            content.find(".popup-message").html(options.simpleMessage);
        }

        var popup = $("<div id=\"popup-window\"></div>");
        popup.appendTo("body");

        var popupWin = popup.kendoWindow({
            animation: options.animate,
            width: options.width,
            modal: true,
            title: options.title,
            resizable: false,
            visible: false,
            close: function () {
                this.destroy();
                dfd.resolve(answer);
            }
        }).data('kendoWindow').content(content).center().open();

        // Bind events on buttons
        popup.find('.popup-window-buttons button.confirm-no, .popup-window-buttons button.confirm-cancel').click(function () {
            answer = false;
            popupWin.close();
        });
        popup.find('.popup-window-buttons button.confirm-yes, .popup-window-buttons button.confirm-ok').click(function () {
            answer = true;
            popupWin.close();
        });

        return dfd.promise();
    };

    /**
    * OBSOLETE - Use TypeScript implementation for new features.
    * Extended function for kendo window used as single form window in global scope
    *
    * @param {string} title - window title text
    * @param {object} prop - some more additional windows properties
    * @return {undefined}
    */
    var _formWindow = function (title, prop) {
        var popup = $("<div id='popup-window' />");
        popup.appendTo("body");

        window.formWin = popup.kendoWindow({
            animation: true,
            width: prop.width || "auto",
            height: prop.height || "auto",
            modal: true,
            title: $("<span>" + title + "</span>").text(),
            visible: false,
            resizable: false,
            close: prop.close || function () { this.destroy(); }
        }).data('kendoWindow');
    };

    /**
    * Extended function for kendo tooltip
    *
    * @param {jQueryObject} jqObj - jquery object, where tooltip should be added
    * @param {string} text - tooltip text
    * @param {object} prop - some more additional tooltip properties
    * @returns {object} tooltip object
    */
    var _tooltip = function (jqObj, text, prop) {
        var tooltip = jqObj.kendoTooltip({
            animation: false,
            autoHide: true,
            callout: true,
            content: text,
            position: prop.position || "bottom",
            showAfter: prop.showAfter || 1000,
            hide: prop.destroyOnHide ? function () { this.destroy(); } : null
        }).data("kendoTooltip");

        // Change color (used on policy edit)
        var changeTooltipColor = function (tooltipElem, color) {
            tooltipElem.css("background-color", color);
            tooltipElem.find(".k-callout-s").css("border-top-color", color);
            tooltipElem.find(".k-callout-n").css("border-bottom-color", color);
            tooltipElem.find(".k-callout-w").css("border-right-color", color);
            tooltipElem.find(".k-callout-e").css("border-left-color", color);
        };

        // Slide tooltip to the right and fade-in (used in main menu)
        var shiftRightWithFadeIn = function (tooltipElem, amount, fadeInDuration) {
            var containerElem = tooltipElem.closest(".k-animation-container");

            var newLeftPosition = (parseInt(containerElem.css("left")) + amount) + "px";
            containerElem.css("opacity", 0);
            setTimeout(function () {
                tooltipElem.css("visibility", "visible");
                containerElem.animate({ opacity: 1, left: newLeftPosition }, fadeInDuration);
            }, 0);
        };

        // Correct position without animation
        var shiftTooltip = function (tooltipElem, shiftAmounts) {
            var containerElem = tooltipElem.closest(".k-animation-container");

            if (shiftAmounts.left > 0) { containerElem.css("left", (parseInt(containerElem.css("left")) - shiftAmounts.left) + "px"); }
            if (shiftAmounts.right > 0) { containerElem.css("left", (parseInt(containerElem.css("left")) + shiftAmounts.right) + "px"); }
            if (shiftAmounts.up > 0) { containerElem.css("top", (parseInt(containerElem.css("top")) - shiftAmounts.up) + "px"); }
            if (shiftAmounts.down > 0) { containerElem.css("top", (parseInt(containerElem.css("top")) + shiftAmounts.down) + "px"); }

            setTimeout(function () { tooltipElem.css("visibility", "visible"); }, 0);
        };

        // Manipulating tooltip appearance:
        if (prop.color || typeof prop.shift === "object" || prop.position === "right" || typeof prop.css === "object") {
            window.initTooltip(tooltip); // Tooltip needs to be initialized in order to bind custom events to it, otherwise not.

            tooltip.popup.bind("open", function (e) {
                var tooltipElem = e.sender.element;

                // Change of color
                if (prop.color) {
                    changeTooltipColor(tooltipElem, prop.color);
                }

                if (typeof prop.css === "object") {
                    tooltipElem.find(".k-tooltip-content").css(prop.css);
                }

                // Prevent animation container from stealing click events
                if (prop.position === "right") {
                    setTimeout(function () {
                        var containerElem = tooltipElem.closest(".k-animation-container");
                        containerElem.css("pointer-events", "none");
                    }, 0);
                }

                // Correcting default position
                if (typeof prop.shift === "object") {
                    tooltipElem.css("visibility", "hidden"); // make it invisible until we set the right position

                    var generalShiftAmounts = {
                        left: prop.shift.left || 0,
                        right: prop.shift.right || 0,
                        up: prop.shift.up || 0,
                        down: prop.shift.down || 0
                    };

                    // Move right with fade-in
                    if (prop.shift.right > 0 && prop.fadeInDuration > 0) {
                        generalShiftAmounts.right = 0;

                        setTimeout(function () { shiftRightWithFadeIn(tooltipElem, prop.shift.right, prop.fadeInDuration); }, 0);
                    }

                    // Move without animations
                    setTimeout(function () { shiftTooltip(tooltipElem, generalShiftAmounts); }, 0);
                }
            });
        }

        return tooltip;
    };

    /**
     * Extended function for kendo tooltip (calls _tooltip fn above every time to produce tooltip).
     * Allows explicit event binding for showing and hinding.
     *
     * @param {jQueryObject} jqObj Element to bind tooltip to
     * @param {string} text Tooltip text
     * @param {object} prop Additional tooltip properties
     * @returns {object} tooltip object or null when using custom event binding as the object is created only temporarily on mouseenter and destroyed on mouseleave
     */
    var _kendoTooltip = function (jqObj, text, prop) {
        var options = {
            position: prop.position, // one of "top" | "right" | "bottom" | "left"
            destroyOnHide: prop.destroyOnHide, // when true then instead of just .hide(), .destroy() method will be called on hide
            color: prop.color, // concrete color for tooltip background
            shift: prop.shift, // object with shift amounts for each direction
            css: prop.css, // object with CSS rules to be applied on tooltip content (such as font-size or font-weight) use prop.color to set backgroud color
            fadeInDuration: prop.fadeInDuration, // number of milliseconds for fading animation (prop.shift.right must be > 0)
            showAfter: prop.showImmediately ? 0 : prop.showAfter || 1000 // delay before display the tooltip
        };

        // Permanent tooltip (one that is only generated once and gets shown/hidden by kendo JavaScript) - suitable for menu and other global elements, that stay on page between content view changes.
        if (!prop.bindEvent && !prop.showImmediately) {
            return _tooltip(jqObj, text, options);
        }


        // Tooltip generated on mouseenter event with showing/hiding controlled in following code:
        var delayInProgress = false;
        var tooltipObj = null;

        jqObj.on("mouseleave.safetica.tooltips click.safetica.tooltips", function () {
            delayInProgress = false;
            if (tooltipObj !== null) {
                tooltipObj.destroy();
            }
        });

        if (prop.showImmediately) {
            // If the tooltip display is not to be delayed (mouseenter event is bound elsewhere).
            // Used inside grids where the delay is set to prevent unncessary wrapping of values into spans to evaluate if the tooltip is needed or not.
            tooltipObj = _tooltip(jqObj, text, options);
            tooltipObj.show();
        } else {
            jqObj.off("mouseenter.safetica.tooltips");
            jqObj.on("mouseenter.safetica.tooltips", function () {
                if (delayInProgress) { return; }

                delayInProgress = true;

                setTimeout(function () {
                    if (!delayInProgress) { return; }
                    tooltipObj = _tooltip(jqObj, text, options);
                    tooltipObj.show();
                    delayInProgress = false;
                }, options.showAfter);
            });
        }

        return null;
    };

    window.kendoCustom = {
        getDefaultOptionsForConfirmationPopup: _getDefaultOptionsForConfirmationPopup,
        confirmationPopup: _confirmationPopup,

        formWindow: _formWindow,

        /**
        * Extended function for kendo drop down list
        * @param {e} e - default widget data parameter
        * @return {undefined}
        */
        createDropDownId: function (e) {
            var inputElement = e.sender.element;
            var inputId = inputElement.attr("id");
            inputElement.closest(".k-widget.k-dropdown").attr("id", inputId + "-dropdown");
        },

        kendoTooltip: _kendoTooltip,

        /**
        * Remove all active tooltips on current view
        * @return {undefined}
        */
        hideAllTooltips: function () {
            var i, tooltips = $("body").find(".k-widget.k-tooltip");

            for (i = 0; i < tooltips.length; i += 1) {
                if ($(tooltips[i]).parent().hasClass("k-animation-container")) {
                    $(tooltips[i]).parent().remove();
                }
            }
        }
    };
});


// html methods
$(function htmlLegacyModule() {
    "use strict";
    var _smallScreen = 480, _mediumScreen = 960;

    var _getDeviceWidth = function () {
        return window.innerWidth > 0
            ? window.innerWidth
            : screen.width;
    };

    var _getDeviceHeight = function () {
        return window.innerHeight > 0
            ? window.innerHeight
            : screen.height;
    };

    /**
     * Determines if the text in the innerElem paramenter fits into datagrid cell specified by outerElem (TD tag).
     * The Grid where this is used should have IsMultiLine property set to false (defined by IGridInfo interface)
     * so that the table cells have text-overflow CSS property set to ellipsis.
     *
     * @param {jQueryObject} innerElem Preferably a SPAN element which is a direct child of the outer TD element.
     * @param {jQueryObject} outerElem Should be DataGrid's cell = TD element
     * @return {bool} True if text has overflown (will not fit in the cell)
     */
    var _hasTextOverflownInDataGrid = function (innerElem, outerElem) {
        if (!innerElem || !outerElem || innerElem.length < 1 || outerElem.length < 1) {
            return false;
        }

        var userAgent = navigator.userAgent.toLowerCase();
        var isFirefox = userAgent.indexOf('firefox') > -1;
        var isEdge = userAgent.indexOf('edge') > -1;
        var isWebkit = userAgent.indexOf('webkit') > -1;

        var oldOuterCssOverflowValue = outerElem.css("text-overflow"); // backup styling value

        outerElem.css("text-overflow", "clip");
        var availableSpace = outerElem[0].clientWidth - 24; // subtracting 24px to account for left & right paddings (each is 12px)
        var neededSpace = innerElem[0].scrollWidth;

        // Cross-browser tweaks (be carefull when cahnging these, do not change order, do not change ELSE-IF to individial IFs!)
        if (isEdge || isFirefox) { neededSpace++; } // Edge + Firefox needs to have this value incremented by 1
        else if (isWebkit) { neededSpace = innerElem.innerWidth(); } // Chrome does not support scrollWidth nor clientWidth on the inner span element

        outerElem.css("text-overflow", oldOuterCssOverflowValue); // revert styling value

        return availableSpace < neededSpace;
    };

    var _makeGridCellValuesOpenPopupIfTooLong_processCells = function (gridElem, dataFieldName, popupWidthPx, fnToProduceWindowTitle) {
        gridElem.find("td[data-column='" + dataFieldName + "'] span.possibly-long-text-to-open-popup").each(function () {
            var statusTextInGridElem = $(this);
            var parentCellElem = statusTextInGridElem.closest("td");

            var isEnoughtSpaceInCell = !_hasTextOverflownInDataGrid(statusTextInGridElem, parentCellElem);
            if (isEnoughtSpaceInCell && statusTextInGridElem.hasClass("clickable")) {
                // Now there is enough space so we revert side-effects we introduced
                statusTextInGridElem.removeClass("clickable"); // Changes text color back to black from blue
                statusTextInGridElem.off("click.safetica.popup"); // removes popup opening event
                parentCellElem.data("kendoTooltip").destroy(); // destroy kendoCustom.kendoTooltip
                statusTextInGridElem.html(statusTextInGridElem.data("originalcontent")); // restore original content

            } else if (!isEnoughtSpaceInCell && !statusTextInGridElem.hasClass("clickable")) {
                var originalCellHtml = statusTextInGridElem.html();

                var popupTitleText = "";
                if (typeof fnToProduceWindowTitle === "function") {
                    popupTitleText = fnToProduceWindowTitle(statusTextInGridElem);
                }

                var popupInnerContents = statusTextInGridElem.clone();

                statusTextInGridElem.on("click.safetica.popup", function (e) {
                    e.preventDefault(); e.stopPropagation();

                    var popupOptions = new WS.Popups.PopupWindowOptions();
                    popupOptions.title = popupTitleText;
                    popupOptions.width = popupWidthPx;
                    popupOptions.hasContentSetAfterInit = true;
                    var popupWindow = new WS.Popups.PopupWindow(popupOptions);

                    var popupContent = $($("#long-grid-text-popup-template").html());
                    popupContent.find("#long-grid-text-popup-content-text").html(popupInnerContents);

                    popupWindow.setContent(popupContent);
                    popupWindow.show();
                });

                // Store original contents which might contain links etc. and replace with text-only version.
                statusTextInGridElem.data("originalcontent", originalCellHtml);
                statusTextInGridElem.html(statusTextInGridElem.text());

                // Initate tooltip suggesting user to click on the mesage to see popup with whole text
                window.kendoCustom.kendoTooltip(parentCellElem, window.Resources.ClickToViewFullMessage, { position: "top" });

                // Make the text blue and change mouse cursor to hand on hover
                statusTextInGridElem.addClass("clickable");
            }
        });
    };


    /**
     * Used to bind all necessary events for the functionality that shortents grid cell text if it is too long and makes it clickable
     * opening a popup window with the original cell contents inside.
     * It should be called on gridDataBound event when the grid already has data loaded.
     * It processes appropriate cell values immediatelly once and sets up an interval to observe for the column size change
     * to update the texts clickable status if necessary (restoring original value if there is enought space and shortening if there is not).
     *
     * @param {jQueryObject} gridElem Grid element that contains the column with possibly very long values.
     * @param {string} dataFieldName Field name (same as in model on server) of the column with possibly very long values.
     * @param {int} popupWidthPx Popup window width in pixels
     * @param {function} fnToProduceWindowTitle (optional) Function to return window title as string. Can accept one attribute that will be {jQueryObject} span.possibly-long-text-to-open-popup inside the processed grid cell.
     * @returns {void}
     */
    var _makeGridCellValuesOpenPopupIfTooLong = function (gridElem, dataFieldName, popupWidthPx, fnToProduceWindowTitle) {
        // Process appropriate cell values immediatelly (once)
        _makeGridCellValuesOpenPopupIfTooLong_processCells(gridElem, dataFieldName, popupWidthPx, fnToProduceWindowTitle);

        if (typeof window.hasTooLongTextInGridToOpenPopupFunctionalityBeenEnabledForGridId === "undefined") {
            window.hasTooLongTextInGridToOpenPopupFunctionalityBeenEnabledForGridId = {}; // Will contain booleans for grid:field pairs that has interval enabled already
        }

        // Observe for the column size change to update the texts clickable status if necessary
        var gridCellKey = gridElem.prop("id") + "_" + dataFieldName;
        if (window.hasTooLongTextInGridToOpenPopupFunctionalityBeenEnabledForGridId[gridCellKey]) {
            return; // Do not enable interval check for this grid and field if it already is enabled (this would happen for refreshable grids on every refresh)
        }

        var gridHeaderCellElem = gridElem.find(".k-grid-header th.k-header[data-field='" + dataFieldName + "']");
        var lastWidth = gridHeaderCellElem.width();
        var intervalHandle = setInterval(function () {
            if (!gridHeaderCellElem.is(":visible")) {
                // Clear interval when the grid is no longer on page
                window.hasTooLongTextInGridToOpenPopupFunctionalityBeenEnabledForGridId[gridCellKey] = false;
                clearInterval(intervalHandle);
                return;
            } else {
                window.hasTooLongTextInGridToOpenPopupFunctionalityBeenEnabledForGridId[gridCellKey] = true;
            }

            var currentWidth = gridHeaderCellElem.width();
            if (currentWidth !== lastWidth) {
                _makeGridCellValuesOpenPopupIfTooLong_processCells(gridElem, dataFieldName, popupWidthPx, fnToProduceWindowTitle);
                lastWidth = currentWidth;
            }
        }, 333);
    };

    /**
     * Looks for elements with CSS class "make-tooltip-from-title" and title attribute.
     * For found elements performs binding of kendoTooltip on hover with value of title attribute and removes the title attribute.
     * Already processed elements will receive "tooltip-made-from-title" CSS class and the "make-tooltip-from-title" class is removed.
     * The element can have place-tooltip-on-{position} CSS class to determine on which side to place the tooltip.
     * Valid positions are: "top" (default), "right", "bottom", "left".
     *
     * @param {string} selectorPrefix Prefix to use when selecting elements to process. Defaults to "body".
     * @returns {void}
     */
    var _makeTooltipsFromTitles = function (selectorPrefix) {
        if (typeof selectorPrefix === "undefined") { selectorPrefix = "body"; }

        $(selectorPrefix + " .make-tooltip-from-title").each(function () {
            var elem = $(this);
            var titleText = elem.prop("title");
            if (titleText) {
                var position = "top";
                if (elem.hasClass("place-tooltip-on-right")) {
                    position = "right";
                    elem.removeClass("place-tooltip-on-right");
                } else if (elem.hasClass("place-tooltip-on-bottom")) {
                    position = "bottom";
                    elem.removeClass("place-tooltip-on-bottom");
                } else if (elem.hasClass("place-tooltip-on-left")) {
                    position = "left";
                    elem.removeClass("place-tooltip-on-left");
                } else if (elem.hasClass("place-tooltip-on-top")) {
                    elem.removeClass("place-tooltip-on-top");
                }

                window.kendoCustom.kendoTooltip(elem, titleText, { position: position, destroyOnHide: true, bindEvent: true });
                elem.prop("title", "").removeClass("make-tooltip-from-title").addClass("tooltip-made-from-title");
            }
        });
    };

    window.html = {
        makeTooltipsFromTitles: _makeTooltipsFromTitles,

        /**
         * Empty function to bind on click event for visually disabled buttons.
         * The need for this function arises when we want disabled button to have tooltip explaining why the button is disabled
         * and disabled buttons cannot have tooltips as browser will not fire mouseenter event on them.
         *
         * @param {event} e Click event (or possibly other)
         * @returns {bool} false all the time.
         */
        fnForDisabledButton: function (e) {
            e.preventDefault(); // prevent possible form submit
            e.stopPropagation(); // prevent DOM bubbling of the event

            return false;
        },

        /**
        * get actual device (browser) window width
        * @return {int} device width in px
        */
        getDeviceWidth: _getDeviceWidth,

        /**
        * get actual device (browser) window height
        * @return {int} device height in px
        */
        getDeviceHeight: _getDeviceHeight,

        /**
         * Strips given HTML value of all tags and returns just text
         *
         * @param {string} value HTML to extract text from
         * @return {string} Text contents without any HTML
         */
        getTextOnly: function (value) {
            return $('<div/>').html(value).text();
        },

        cancelBubble: function (e) {
            e = e || window.event;
            e.cancelBubble = true;

            if (e.stopPropagation) {
                e.stopPropagation();
            }
        },

        /**
         * Has window small screen width
         *
         * @return {bool} True if window qualify as small
         */
        isSmallScreen: function () {
            return _getDeviceWidth() <= _smallScreen;
        },

        /**
        * Has window medium or smaller screen width
        *
        * @return {bool} True if window qualify as medium (might be small)
        */
        isMediumScreen: function () {
            return _getDeviceWidth() <= _mediumScreen;
        },

        /**
        * function for scrollbar width
        * @return {int} scrollbar width in px
        */
        getScrollbarWidth: function () {
            var outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.width = "100px";
            outer.style.msOverflowStyle = "scrollbar";

            document.body.appendChild(outer);
            var widthNoScroll = outer.offsetWidth;
            outer.style.overflow = "scroll";

            var inner = document.createElement("div");
            inner.style.width = "100%";
            outer.appendChild(inner);

            var widthWithScroll = inner.offsetWidth;
            outer.parentNode.removeChild(outer);
            return widthNoScroll - widthWithScroll;
        },

        // Use this to shorten strings that are too long to fit on one (or more) lines and provide a tooltip with full text on hover
        shortenTextWithTooltip: function (obj, lineCount, tooltipData) {
            var lineHeight = parseInt(obj.css("line-height"));
            var result = lineHeight * lineCount;

            if (obj[0].offsetHeight > result || obj[0].scrollWidth > obj.innerWidth()) {
                clamp(obj[0], lineCount);

                if (tooltipData) {
                    window.kendoCustom.kendoTooltip($(obj[0]), tooltipData.fullText, { position: tooltipData.position, destroyOnHide: true, bindEvent: true });
                }
            }
        },

        hasTextOverflownInDataGrid: _hasTextOverflownInDataGrid,

        makeGridCellValuesOpenPopupIfTooLong: _makeGridCellValuesOpenPopupIfTooLong,


        /**
        * bind custom context menu to some element
        * @param {customMenuId} customMenuId - id element located in Views/Shared/_CustomMenus.cshtml
        * @param {element} element where to bind custom menu
        * @param {showAttr} showAttr - define if show attribute is necessary for showing menu
        * @return {undefined}
        */
        bindCustomMenu: function (customMenuId, element, showAttr) {
            element.bind("contextmenu", function (e) {
                e.preventDefault();

                if (showAttr && !$(e.target).attr(showAttr)) {
                    return null;
                }

                var menuId = "#" + customMenuId;
                if ($(e.target).attr("data-menu")) {
                    menuId += "-" + $(e.target).attr("data-menu");
                }

                $(menuId).css({
                    top: e.pageY + "px",
                    left: e.pageX + "px",
                    display: "block"
                });
            });
        },

        /**
        * help method for hidding custom menu after scroll
        * @param {customMenuId} customMenuId - id element located in Views/Shared/_CustomMenus.cshtml
        * @return {undefined}
        */
        hideCustomMenuIfScroll: function (customMenuId) {
            document.addEventListener('scroll', function () {
                if (document.getElementById(customMenuId).style.display !== "none") {
                    $("#" + customMenuId).hide(100);
                }
            }, true);
        },

        /**
        * help method for hidding custom menu after click
        * @param {customMenuId} customMenuId - id element located in Views/Shared/_CustomMenus.cshtml
        * @return {undefined}
        */
        hideCustomMenuIfClick: function (customMenuId) {
            $(window).bind("mousedown", function (e) {
                if (document.getElementById(customMenuId).style.display !== "none" && !$(e.target).attr("data-nohide")) {
                    $("#" + customMenuId).css("display", "none");
                }
            });
        },

        /**
        * hide custom menu immediately
        * @param {e} e input dom element
        * @return {undefined}
        */
        hideCustomMenu: function (e) {
            $(e).parent().css("display", "none");
        },

        // DEPRECATED - Instead use UiBlocker methods directly.
        isReloading: function (targetElems, shouldBeEnabled) {
            WS.Helpers.UiBlocker.legacyHtmlIsReloading(targetElems, shouldBeEnabled);
        },

        /**
        * add icon for hovering on some element
        * @param {obj} obj - target object
        * @param {icon} icon - specific icon from pack
        * @return {undefined}
        */
        addIconOnHover: function (obj, icon) {
            obj.css("cursor", "pointer");

            obj.on("mouseover", function () {
                if (obj.find(".wcicon-s.wcicon-" + icon + ".hover-icon").length === 0) {
                    obj.append("<span class='wcicon-s wcicon-" + icon + " hover-icon' />");
                }
            });
            obj.on("mouseleave", function () {
                obj.find(".wcicon-s.wcicon-" + icon + ".hover-icon").remove();
            });
        },

        /**
        * switching to cursor wait
        * @return {undefined}
        */
        addWait: function () {
            $("html").addClass("wait");
        },

        removeWait: function () {
            $("html").removeClass("wait");
        },

        /**
         * Sets the width of target elements to the widest one.
         * Used on .description cells in settings.
         *
         * @param {jQueryObject} targetElements Collection of elements that are supposed to have same width
         * @param {int} maxWidth Maximum width in pixels. The resulting width will never go over this value. Leave undefined or 0 to disable limit.
         * @return {void}
         */
        alignWidthOfElements: function (targetElements, maxWidth) {
            if (typeof maxWidth === "undefined") { maxWidth = 0; }

            var widestElementWidth = 0;
            targetElements.each(function () {
                if ($(this).width() > widestElementWidth) { widestElementWidth = $(this).width(); }
            });

            var resultingWidth = maxWidth > 0 && widestElementWidth > maxWidth ? maxWidth : widestElementWidth;
            targetElements.width(resultingWidth);
        },

        /**
         * Sets the height of target elements that fit in row beside each other to the tallest one.
         * Used on floating boxes in device details.
         *
         * @param {jQueryObject} targetElements Collection of elements that are supposed to have same height (if they are in one row).
         * @return {void}
         */
        alignHeightOfFloatingElements: function (targetElements) {
            var rowElemsSelectors = {};

            var lastOffset = 0; // position from top of document
            targetElements.each(function () {
                var elem = $(this);
                var currentOffset = Math.floor(elem.offset().top);
                var currentRowKey = "rowOffset" + currentOffset;
                var elemSelector = "#" + elem.prop("id");
                if (elemSelector === "#") {
                    var randomId = identify.makeId(16) + "_random";
                    elem.prop("id", randomId);
                    elemSelector = "#" + randomId;
                }

                if (currentOffset > lastOffset) {
                    rowElemsSelectors[currentRowKey] = elemSelector;
                } else {
                    rowElemsSelectors[currentRowKey] += ", " + elemSelector;
                }

                lastOffset = currentOffset;
            });

            var syncHeights = function (rowElems) {
                if (rowElems.length < 1) { return; }

                rowElems.height("auto");

                var tallestElementHeight = 0;
                rowElems.each(function () {
                    if ($(this).height() > tallestElementHeight) { tallestElementHeight = $(this).height(); }
                });

                rowElems.height(tallestElementHeight);
            };

            for (var currentRowKey in rowElemsSelectors) {
                if (currentRowKey.startsWith("rowOffset")) {
                    syncHeights($(rowElemsSelectors[currentRowKey]));
                }
            }

        }
    };
});

(function ($) {
    "use strict";
    // element have scrollbar
    $.fn.hasScrollBar = function () {
        return this.get(0).scrollHeight > this.height();
    };
}(jQuery));


$(function () {
    "use strict";

    // Make kendoTooltips from titles
    setInterval(function () { window.html.makeTooltipsFromTitles(); }, 1000);
});
