// tslint:disable:max-classes-per-file

import { Exception } from "./Exception";

export class WsException extends Exception {}

export class ArgumentException extends WsException {}
export class ArgumentOutOfRangeException extends WsException {}
export class InvalidUsageException extends WsException {}
export class NotImplementedException extends WsException {}
export class NullValueException extends WsException {}
export class UndefinedValueException extends WsException {}

export class DateTimeConvertException extends WsException {}
export class DatePickerException extends WsException {}
export class MenuException extends WsException {}
export class SpaUserTreeException extends WsException {}
export class RequestHelperException extends WsException {}
export class RequestHelperTokenException extends RequestHelperException {}

export class ElementNotFoundException extends WsException {
    constructor(elementDescription: string, inner?: Exception) {
        super("Element not found: " + elementDescription, inner);
    }
}

// tslint:enable:max-classes-per-file
