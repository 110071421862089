
import { IWebConsoleWindow } from "../../../window.globals";
import { RequestHelper } from "./RequestHelper";



declare const window: IWebConsoleWindow;

let defaultRequestHelper: RequestHelper;
if (!window.WsGlobals.defaultRequestHelper) {
    defaultRequestHelper = new RequestHelper();
    window.WsGlobals.defaultRequestHelper = defaultRequestHelper;
} else {
    defaultRequestHelper = window.WsGlobals.defaultRequestHelper;
}

export { defaultRequestHelper };
