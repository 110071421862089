
import { Identify } from "../Helpers/Identify";
import { IExtendedKendoTooltip, IKendoCustomLegacySupport } from "../LegacyJsSupport/KendoCustomLegacySupport";
import { FormValidationOptions } from "./FormValidationOptions";

// Legacy JS code used for tooltips:
declare var kendoCustom: IKendoCustomLegacySupport;

// JQueryValidation.Validator quick fix:
interface IRealJQueryValidationValidator extends JQueryValidation.Validator {
    defaultShowErrors(): void; // JQueryValidation.Validator in fact does have this method but TS complains about it not having it, so there...
}

/**
 * jQuery-based form validator
 */
export class FormValidator {
    /** Key used to get/set instance of this class to a DOM node via jQuery's .data(key). */
    public static get jQueryDataName(): string { return "FormValidator"; }
    /** Gets an instance of this class from DOM node specified by jQuery object. */
    public static getFromElem(elem: JQuery): FormValidator | undefined { return elem.data(this.jQueryDataName); }
    /** Sets an instance of this class for DOM node specified by jQuery object. */
    public static setForElem(elem: JQuery, instance: FormValidator): void { elem.data(this.jQueryDataName, instance); }

    public formElem: JQuery;
    public options: JQueryValidation.ValidationOptions;

    private _validator: IRealJQueryValidationValidator;
    private _existingTooltips: Map<string, IExtendedKendoTooltip>;
    private _invalidInputClass = "invalid-form-field";
    private _validInputClass = "valid-form-field"; // empty because we do not style valid inputs differently from default

    /**
     * Initializes new instance of jQuery-based form validator
     * @param formElem Form to enable validation on as jQuery object
     * @param options Validation options
     */
    constructor(formElem: JQuery, options: FormValidationOptions) {

        this.formElem = formElem;
        this._existingTooltips = new Map<string, IExtendedKendoTooltip>();

        this.options = {
            ignoreTitle: true,
            rules: options.rules,
            messages: options.messages === null ? {} : options.messages,
            validClass: this._validInputClass,
            errorClass: this._invalidInputClass,

            // Empty fn because the errors are placed into DOM by bindValidationMsgTooltip method as tooltips
            // but we still need to call validator.defaultShowErrors() which would have them placed in DOM too by calling this method.
            errorPlacement: (msgElem, validatedElem) => { }, // tslint:disable-line:no-empty

            // This is called when eager validation is on (after first submit attempt when there were errors)
            // and field that was marked as invalid is now considered valid.
            success: (labelElem, element) => {
                const inputElem = $(element);

                if (inputElem.hasClass(this._invalidInputClass)) {
                    // Destroy tooltip if it was bound
                    this._removeTooltip(this._getFieldKey(inputElem));

                    inputElem.removeClass(this._invalidInputClass).addClass(this._validInputClass);
                }
            },

            showErrors: (errorMap, errorList) => {
                this._validator.defaultShowErrors(); // Needed to mark fields valid when validation requirement are met after first the field was marked as invalid.

                for (let i = 0; i < errorList.length; i++) {
                    const inputElem = $(errorList[i].element);
                    const validationMsg = errorList[i].message;

                    inputElem.removeClass(this._validInputClass).addClass(this._invalidInputClass);

                    this._bindValidationMsgTooltip(inputElem, validationMsg);
                }
            },
        };

        const serverValidationErrors: { [key: string]: string } =  {};
        let serverValidationErrorCount = 0;

        // If options has specified element containing field validation messages sent by server, parse them and bind to appropriate elements.
        if (options.serverMessagesElem && options.serverMessagesElem.length === 1) {
            options.serverMessagesElem.find("span.field-validation-error").each(function(this: HTMLSpanElement) {
                const msgElem = $(this);
                const fieldName: string = msgElem.data("valmsg-for");
                const validationMessage = msgElem.text();

                if (fieldName && validationMessage) {
                    serverValidationErrors[fieldName] = validationMessage;
                    serverValidationErrorCount++;
                }
            });

            if (serverValidationErrorCount > 0) {
                // Activate eager validation mode
                this.options.onkeyup = (element, event) => { $(element).valid(); };
                this.options.onfocusout = (element, event) => { $(element).valid(); };
            }
        }

        // Activate form validator, similar to $(formSelector).validate(options) in VanillaJS.
        this._validator = this.formElem.validate(this.options) as IRealJQueryValidationValidator;

        // Attaching server-sent validation messages to fields
        if (serverValidationErrorCount > 0) {
            this._validator.showErrors(serverValidationErrors);
        }
        this._validator.focusInvalid(); // Focus first invalid field

        // Add reference to self as data attribute
        FormValidator.setForElem(this.formElem, this);
    }

    /**
     * Sets the errors from script, typically after receiving them as FormValidationErrorJsonResultModel.
     * @param errorMap Map of fieldName: errorMessage messages.
     */
    public setErrors(errorMap: Map<string, string>): void {
        this.resetForm();
        this._validator.showErrors(errorMap);
        this._validator.focusInvalid(); // Focus first invalid field
    }

    /**
     * Use to check if form inputs are currently valid.
     * Will only show the messages when triggerSubmit == true
     *
     * This method has side-effects:
     *  - Calls checkForm() method which turns on eager validation.
     *
     * @param triggerSubmit Set to true if is safe to trigger submit on the form (to display the validation messages on AJAX forms)
     */
    public validate(triggerSubmit = false): boolean {
        if (triggerSubmit)
            this.formElem.trigger("submit"); // This will show validation messages
        else
            this._validator.checkForm(); // This won't show any validation messages

        return this._validator.valid();
    }

    /**
     * Resets the form validation state (turns off eager validation).
     */
    public resetForm(): void {
        this._validator.resetForm();
    }

    private _bindValidationMsgTooltip(inputElem: JQuery, validationMsg: string) {
        const fieldKey = this._getFieldKey(inputElem);

        // Remove any previously added tooltip
        this._removeTooltip(fieldKey);

        // Build tooltip object
        const tooltip = kendoCustom.kendoTooltip(inputElem, validationMsg, {
            position: "bottom",
            autoHide: false,
            bindEvent: false,
            destroyOnHide: false,
            color: "#be1622",
        });

        // Add to internal dictionary
        this._existingTooltips.set(fieldKey, tooltip);

        // Bind events
        inputElem.on("focusin.safetica.validation mouseenter.safetica.validation", (event) => {
            this._hideOtherTooltips(fieldKey);
            tooltip.show();
        });

        inputElem.on("focusout.safetica.validation mouseleave.safetica.validation", (event) => {
            tooltip.hide();
        });

        // Show the tooltip immediately if the input already has focus
        if (inputElem.is(":focus")) {
            tooltip.show();
        }
    }

    /**
     * Hides and destroys tooltip for given input identified by fieldKey.
     * @param fieldKey Field key of the input which tooltip should be removed
     */
    private _removeTooltip(fieldKey: string) {
        if (this._existingTooltips.has(fieldKey)) {
            const tooltipReference = this._existingTooltips.get(fieldKey);
            if (tooltipReference !== undefined) {
                tooltipReference.hide();
                tooltipReference.destroy();
                this._existingTooltips.delete(fieldKey);
            }
        }
    }

    /**
     * Hides all form validation tooltips except for the one specified by fieldKey argument.
     * @param fieldKey Field key of the input which tooltip shall remain displayed
     */
    private _hideOtherTooltips(fieldKey: string) {
        this._existingTooltips.forEach((tooltip, key, map) => {
            if (key !== fieldKey) {
                tooltip.hide();
            }
        });
    }

    /**
     * Builds an identifier for the field (and generates it if the form/field has no usable attribute)
     * @param inputElem Form input element to create key for.
     */
    private _getFieldKey(inputElem: JQuery): string {
        let identifier = "field_";

        const formElem = inputElem.closest("form");
        if (formElem.prop("id")) {
            identifier += formElem.prop("id") + "_";
        } else if (formElem.prop("name")) {
            identifier += formElem.prop("name") + "_";
        } else {
            const newFormId = Identify.makeId(8);
            formElem.prop("id", newFormId);
            identifier += newFormId + "_";
        }

        if (inputElem.prop("id")) {
            identifier += inputElem.prop("id");
        } else if (inputElem.prop("name")) {
            identifier += inputElem.prop("name");
        } else {
            const newInputId = Identify.makeId(8);
            inputElem.prop("id", newInputId);
            identifier += newInputId;
        }

        return identifier;
    }
}

