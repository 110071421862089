
import { isUndefined } from "util";
import { IWebConsoleWindow } from "../../window.globals";
import { defaultConsoleLogger } from "./Logging/DefaultConsoleLogger";



declare const window: IWebConsoleWindow;

/**
 * Allows to find developers' mistake - duplicate IDs of HTML elements in a page.
 */
export class DuplicateIdFinder {
    public duplicates: Map<string, number> = new Map<string, number>();

    private _document: Document;
    private _pageUrl: string;
    private _logger = defaultConsoleLogger;

    constructor(url: string, document: Document) {
        this._pageUrl = url;
        this._document = document;
    }

    public findDuplicates(): void {

        const nodes = this._document.querySelectorAll("[id]");
        const totalNodes = nodes.length;
        const allIds: Map<string, number> = new Map<string, number>();

        for (let i = 0; i < totalNodes; i++) {
            const id = nodes[i].id;
            if (isUndefined(id))
                continue;

            let count: number | undefined = allIds.get(id);
            if (isUndefined(count))
                count = 1;
            else
                count++;

            allIds.set(id, count);
        }

        allIds.forEach((count, id) => {
            if (count > 1)
                this.duplicates.set(id, count);
        });
    }

    public wereDuplicatesFound(): boolean {
        return this.duplicates.size > 0;
    }

    public logDuplicatesReport(): void {
        if (this.wereDuplicatesFound())
            this._logger.warn("Duplicate IDs exist on page! URL: " + this._pageUrl);
        else
            return;

        this.duplicates.forEach((count, id) => {
            this._logger.warn(`   ID "${id}" exists ${count} times on this page!`);
        });
    }

    public getDuplicatesAsString(): string {
        if (!this.wereDuplicatesFound())
            return "";

        const items: string[] = [];
        this.duplicates.forEach((count, id) => {
            items.push(`"${id}": ${count}`);
        });

        return `{ ${items.join(", ")} }`;
    }

}

// This will log warning level messages to console in debug configuration (in case duplicates are found).
if (window.WsGlobals.isDebug) {
    let lastResults = ""; // serialized map of duplicates
    window.setInterval(() => {
        const finder = new DuplicateIdFinder(window.location.href, window.document);
        finder.findDuplicates();

        const currentResults = finder.getDuplicatesAsString();
        if (lastResults !== currentResults) {
            finder.logDuplicatesReport();
            lastResults = currentResults;
        }
    }, 5000);
}
