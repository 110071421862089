
import * as DetectBrowser from "detect-browser";
import { UserAgentInfo } from "./UserAgentInfo";



/**
 * Capable of detecting browser kind + version and to make a decision whether it is supported by development team.
 */
export class UserAgentDetector {
    private _userAgentInfo: UserAgentInfo;

    constructor() {
        this._userAgentInfo = this.getUserAgentInfo(window.navigator.userAgent);
    }

    /** Gets UserAgentInfo object for current browser. */
    public get userAgentInfo(): UserAgentInfo {
        return this._userAgentInfo;
    }

    /** Gets string representation of current browser. I.e.: "Firefox 64", "Chrome 42" or "N/A" for unknown. */
    public get userAgentNameAndVersion(): string {
        return this._userAgentInfo.toString();
    }

    /** Gets the result of support evaluation for current browser. */
    public get isUserAgentSupported(): boolean {
        return this.getSupportResult(this._userAgentInfo);
    }

    /** Parses given UA string (typically navigator.userAgent) into UserAgentInfo object. */
    public getUserAgentInfo(uaString: string): UserAgentInfo {
        const browserInfo = DetectBrowser.parseUserAgent(uaString);
        let uai = new UserAgentInfo();
        if (browserInfo && browserInfo.name && browserInfo.version)
            uai = new UserAgentInfo(browserInfo.name, browserInfo.version);

        if (uai.isUnknown)
            uai = this._getFallbackUserAgentInfo(uaString);

        return uai; // uai.isUnknown can still be true
    }

    /** Boolean indicating if user agent is supported while developing WS and features should work. */
    public getSupportResult(uai: UserAgentInfo): boolean {
        // Blacklisting approach => When a browser is not mentioned, it defaults to being supported.
        return !(
            uai.isUnknown // Unknown, not detected by npm package nor by our RegExp parser (something weird like mobile, TV or text-based browser)
            || (uai.name === "Safari" && uai.version > 500) // Missinterpreted Unknown for Safari

            // Supported browsers, from particular version onwards
            || (uai.name === "Chrome" && uai.version < 51) // Chrome 51: May 2016
            || (uai.name === "Firefox" && uai.version < 54) // Firefox 54: June 2017
            || (uai.name === "Edge" && uai.version < 15) // Edge 15: April 2017
            || (uai.name === "Safari" && uai.version < 10) // Safari 10: September 2016
            || (uai.name === "Opera" && uai.version < 38) // Opera 38: June 2016
            || (uai.name === "Vivaldi" && uai.version < 1.5) // has Chromium 51+ inside
            || (uai.name === "Yandex Browser" && uai.version < 17) // has Chromium 51+ inside

            // Unsupported
            || uai.name === "Internet Explorer" // Dead, Last release: 2013 (IE11)
            || uai.name === "AOL" // Dead, based on Trident (IE7)
            || uai.name === "BlackBerry" // Mobile only
            || uai.name === "Opera Mini" // Mobile only
            || uai.name === "Android" // Mobile only
            || uai.name === "Samsung" // Mobile only
            || uai.name === "iOS" // Mobile only
            || uai.name === "iOS WebView" // Mobile only
            || uai.name === "KakaoTalk" // Mobile only
            || uai.name === "Silk" // Kindles + TVs only
        );
    }

    private _getFallbackUserAgentInfo(uaString: string): UserAgentInfo {
        const result = this._parseUserAgentLegacy(uaString);
        if (result) {
            const parts = result.split(" ");
            if (parts.length > 1) {
                const name = parts[0];
                const version = parts[1];

                const uai = new UserAgentInfo(name, version);
                if (uai.name === "Safari" && uai.version > 333) {
                    // Wrong detection of mobile agents, ignore this result.
                } else {
                    return uai;
                }
            }
        }

        return new UserAgentInfo();
    }

    /**
     * The "detect-browser" npm package only supports "recent" browsers (IE8+).
     * This parser is used to second try if DetectBrowser.parseUserAgent method fails to detect browser.
     */
    private _parseUserAgentLegacy(userAgent: string): string {
        let mName = userAgent.match(/(konqueror|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        let mVersion;
        if (/trident/i.test(mName[1])) {
            mVersion = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return "MSIE " + (mVersion[1] || "");
        }

        if (mName[1] === "Chrome") {
            mVersion = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (mVersion !== null)
                return mVersion.slice(1).join(" ").replace("OPR", "Opera");
        }

        mName = mName[2] ? [mName[1], mName[2]] : [];
        mVersion = userAgent.match(/version\/(\d+)/i);
        if (mVersion !== null)
            mName.splice(1, 1, mVersion[1]);

        return mName.join(" ");
    }

}
