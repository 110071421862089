/*! SimplePages entrypoint */

import { WebConsoleEvents } from "./Modules/Constants/WebConsoleEvents";
import { ConsoleLogger } from "./Modules/Helpers/Logging/ConsoleLogger";
import { defaultConsoleLogger } from "./Modules/Helpers/Logging/DefaultConsoleLogger";
import { defaultRequestHelper } from "./Modules/Helpers/Requests/DefaultRequestHelper";
import { RequestHelper } from "./Modules/Helpers/Requests/RequestHelper";
import { UserAgentDetector } from "./Modules/Helpers/UserAgent/UserAgentDetector";

import * as FormValidation from "./Modules/FormValidation";

import "./Modules/Helpers/DuplicateIdFinder";



let helpers: {
    ConsoleLogger: ConsoleLogger,
    RequestHelper: RequestHelper;
    UserAgentDetector: UserAgentDetector;
};

$(function initSimplePageHelpers() {
    try {
        helpers = {
            ConsoleLogger: defaultConsoleLogger,
            RequestHelper: defaultRequestHelper,
            UserAgentDetector: new UserAgentDetector(),
        };
    } catch (e) {
        defaultConsoleLogger.exception(e);
    }
});



export { helpers as Helpers };

export { FormValidation };



import "../legacy-js/others/googleAnalytics";
import "../legacy-js/others/helpers";
import "../legacy-js/others/html";
import "../legacy-js/others/messages";



$(() => { $(window).trigger(WebConsoleEvents.global.entrypointLoaded); });
