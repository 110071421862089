/**
 * Status Messages widget
 *
 * This Kendo widget is called by JavaScript inserted into page by using StatusMessagesHtmlHelper in a Razor view template.
 * E.g.: @Html.StatusMessage(Resources.ResTextId, StatusMessageType.Information, "MsgId")
 *
 * The helper produces code such as: $("#some-selector").kendoCustomStatusMessage({ content: "Some message", type: "Information" });
 * and optionaly the target element in place where the message is supposed to appear.
 */
(function ($) {
    "use strict";

    var kendo = window.kendo;
    var ui = kendo.ui;
    var Widget = ui.Widget;

    var CustomStatusMessage = Widget.extend({
        init: function (element, options) {
            var that = this;
            Widget.fn.init.call(that, element, options);
            that._create();
        },

        _create: function () {
            var that = this;
            var options = this.options;

            var targetElement = that.element;
            targetElement.addClass("status-message-container");

            var wrappingTable = $("<table class=\"status-message\"><tbody><tr></tr></tbody></table>");
            wrappingTable.addClass("status-message-" + options.type.toLowerCase());

            if (options.additionalClasses) { wrappingTable.addClass(options.additionalClasses); }
            if (options.isCentered) { wrappingTable.addClass("centered"); }

            var iconClassSuffix = "undefined";
            if (options.type in options.customTypes) {
                iconClassSuffix = options.customTypes[options.type].Icon.toLowerCase();
            } else {
                console.log("Warning: Created status message with undefined icon for its type: " + options.type);  // eslint-disable-line no-console
            }

            var rowElem = wrappingTable.find("tr");

            // Icon
            that.icon = $("<td class=\"status-message-icon\"><span class=\"wcicon-s wcicon-" + iconClassSuffix + "\"></span></td>");
            rowElem.append(that.icon);

            // Message text
            that.message = $("<td class=\"status-message-text\"></td>");
            that.message.html(options.content);
            rowElem.append(that.message);

            // Hide button
            if (typeof options.dismissAction === "function") {
                var hideButtonElem = $("<i class=\"k-icon k-i-close close-icon\"></i>").on("click", function () {
                    options.dismissAction();
                });
                window.kendoCustom.kendoTooltip(hideButtonElem, window.Resources.DismissButtonTooltip, { position: "left", destroyOnHide: true, bindEvent: true });

                var hideButtonCellElem = $("<td class=\"status-message-button\"></td>");
                hideButtonCellElem.append(hideButtonElem);

                rowElem.append(hideButtonCellElem);
            }

            // Inject whole message into page
            targetElement.append(wrappingTable);
        },

        options: {
            name: "CustomStatusMessage",
            content: "",
            additionalClasses: null,
            isCentered: false,
            customTypes: window.StatusMessagesData,
            type: null,
            dismissAction: null,
        }
    });

    ui.plugin(CustomStatusMessage);
}(jQuery));
