
import { isFunction } from "util";
import { IWebConsoleWindow } from "../../../window.globals";
import { Exception } from "../../Exceptions/Exception";
import { Identify } from "../Identify";
import { LoggingLevel } from "./LoggingLevel";



declare const window: IWebConsoleWindow;

/**
 * Provides unified console logging functions.
 * Output is by default limited to errors and warning only in Release configuration (defined in _Layout.cshtml).
 */
export class ConsoleLogger {
    /** Prefix ending with space or empty string. */
    public readonly prefix: string;
    /** Level at which this instance starts logging. */
    public level: LoggingLevel;

    /** True if the console supports grouping of logs (all modern browsers do support it). */
    public get isConsoleGroupable(): boolean {
        return isFunction(window.console.groupCollapsed) && isFunction(window.console.groupEnd);
    }

    /**
     * Construct new logger instance
     * @param level Optional minimal level of messages to log with this logger
     * @param identifier Optional identifier to distinguish output from a module for example
     */
    constructor(level?: LoggingLevel, identifier?: string) {

        this.prefix = "";
        if (identifier) {
            this.prefix = "[" + identifier + "] ";
        } else if (window.WsGlobals.defaultLogger) { // Define prefix to distinguish this logger instance from default logger.
            this.prefix = "[" + Identify.makeId(3).toUpperCase() + "] ";
        }

        if (level !== undefined) {
            this.level = level;
        } else if (window.WsGlobals.consoleLoggingLevel !== undefined) {
            this.level = window.WsGlobals.consoleLoggingLevel;
        } else {
            this.level = LoggingLevel.Debug;
        }
    }

    /**
     * Logs debug message to console. Message is prefixed with "DEBUG: "
     * @param msg Message
     * @param trace When true (default) and console supports grouping, console.trace method will be called to allow to trace source of message.
     */
    public debug(msg: string, trace: boolean = true): void {
        if (this.level === LoggingLevel.Debug) {
            const output = this.prefix + "DEBUG: " + msg;

            if (trace && this.isConsoleGroupable) {
                this._logWithTrace(output);
            } else {
                if (typeof window.console.debug === "function") // IE 10 does not have debug method on console
                    window.console.debug(output);
                else
                    window.console.log(output);
            }
        }
    }

    /**
     * Logs message information to console
     * @param msg Message
     * @param trace When true (default) and console supports grouping, console.trace method will be called to allow to trace source of message.
     */
    public info(msg: string, trace: boolean = true): void {
        if (this.level >= LoggingLevel.Info) {
            if (trace && this.isConsoleGroupable)
                this._logWithTrace(this.prefix + "INFO: " + msg);
            else
                window.console.info(this.prefix + msg);
        }
    }

    /**
     * Logs general message to console (level is same as info)
     * @param msg Message
     * @param trace When true (default) and console supports grouping, console.trace method will be called to allow to trace source of message.
     */
    public log(msg: string, trace: boolean = true): void {
        if (this.level >= LoggingLevel.Info) {
            if (trace && this.isConsoleGroupable)
                this._logWithTrace(this.prefix + msg);
            else
                window.console.log(this.prefix + msg);
        }
    }

    /**
     * Logs message to console and refixes it with "WARNING: "
     * @param msg Message
     * @param trace When true (default) and console supports grouping, console.trace method will be called to allow to trace source of message.
     */
    public warn(msg: string, trace: boolean = true): void {
        if (this.level >= LoggingLevel.Warning) {
            if (!msg.startsWith("WARNING"))
                msg = "WARNING: " + msg;

            if (trace && this.isConsoleGroupable)
                this._logWithTrace(this.prefix + msg);
            else
                window.console.warn(this.prefix + msg);
        }
    }

    /**
     * Logs message as error to console (+ has stacktrace in FF)
     * @param msg Message
     * @param trace When true (default) and console supports grouping, console.trace method will be called to allow to trace source of message.
     */
    public error(msg: string, trace: boolean = true): void {
        if (this.level >= LoggingLevel.Error) {
            if (trace && this.isConsoleGroupable)
                this._logWithTrace(this.prefix + "ERROR: " + msg);
            else
                window.console.error(this.prefix + msg);
        }
    }

    /**
     * Logs exception or error as specified level message (error by default).
     * Typically used in try-catch block as simple error handler.
     * @param e Either custom exception or vanilla JS error object.
     */
    public exception(e: Exception | Error, level: LoggingLevel = LoggingLevel.Error): void {
        let msg: string;

        if (e instanceof Exception) {
            msg = e.toString();
        } else {
            msg = e.name + ": " + e.message;
        }

        switch (level) {
            case LoggingLevel.Debug:
                this.debug(msg); break;

            case LoggingLevel.Info:
                this.info(msg); break;

            case LoggingLevel.Warning:
                this.warn(msg); break;

            case LoggingLevel.Error:
            default:
                this.error(msg); break;
        }
    }

    /**
     * Logs serialized object representation into console on DEBUG level.
     * @param obj Anything serializable with JSON.stringify()
     * @param identifier Optional prefix to identify what is being output to console.
     */
    public logSerialized(obj: any, identifier?: string): void {
        if (this.level < LoggingLevel.Debug) return;

        let outputJson = "";

        if (!this.isConsoleGroupable && identifier) {
            outputJson += identifier + ": ";
        }

        try {
            outputJson += this.isConsoleGroupable ? JSON.stringify(obj, null, 4) : JSON.stringify(obj);
        } catch (e) {
            outputJson += "Could not call JSON.stringify() upon object. " + e;
        }

        if (this.isConsoleGroupable) {
            window.console.groupCollapsed("JSON representation of " + identifier + ":");
            window.console.log(outputJson);
            window.console.groupEnd();
        } else {
            this.log(outputJson);
        }
    }

    private _logWithTrace(msg: string): void {
        if (this.isConsoleGroupable) {
            window.console.groupCollapsed(msg);
            window.console.trace();
            window.console.groupEnd();
        } else {
            window.console.trace();
        }
    }
}
