
import { IWebConsoleWindow } from "../../../window.globals";
import { ConsoleLogger } from "./ConsoleLogger";



declare const window: IWebConsoleWindow;

// Default global ConsoleLogger instance.
// Value of window.WsGlobals.consoleLoggingLevel is set in _Layout.cshtml.
let defaultConsoleLogger: ConsoleLogger;
if (!window.WsGlobals.defaultLogger) {
    defaultConsoleLogger = new ConsoleLogger();
    window.WsGlobals.defaultLogger = defaultConsoleLogger;
} else {
    defaultConsoleLogger = window.WsGlobals.defaultLogger;
}

export { defaultConsoleLogger };
