/**
 * OBSOLETE - Use TypeScript implementation for new features.
 */
$(function identifyLegacyHelper() {
    "use strict";

    window.identify = {
        /**
         * Generates pseudo-random alpha-numeric ID, (starting with letter)
         *
         * @param {int} length How many characters should the ID have
         * @return {string} Pseudo-random ID
         */
        makeId: function (length) {
            var text = "", i;
            var possible = "abcdefghijklmnopqrstuvwxyz"; // start with just letters

            for (i = 0; i < length; i += 1) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));

                if (i === 0) { possible += "0123456789"; } // after fisrt letter, expand possibilities with numbers
            }

            return text;
        },

        /**
         * Generates pseudo-random numeric ID, (starting with non-zero)
         *
         * @param {int} length How many numbers should the ID have
         * @return {string} Pseudo-random ID
         */
        makeNumId: function (length) {
            var text = "", i;
            var possible = "123456789"; // start without zero

            for (i = 0; i < length; i += 1) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));

                if (i === 0) { possible += "0"; } // after fisrt non-zero number, expand possibilities with zero
            }

            return text;
        },

        /**
         * Generates pseudo-random ID consisting of letters only
         *
         * @param {int} length How many letters should the ID have
         * @return {string} Pseudo-random ID
         */
        makeStringId: function (length) {
            var text = "", i;
            var possible = "abcdefghijklmnopqrstuvwxyz";

            for (i = 0; i < length; i += 1) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            return text;
        }
    };
});


/**
 * Functions preventing numeric value overflow (used a lot in filters)
 */
$(function numLegacyHelper() {
    "use strict";
    // TODO: Revise documentation for num JS namespace

    window.num = {
        isInteger: function (value) {
            var result = value % 1 === 0
                ? value.toString()
                : "";
            return result;
        },

        preventInt7: function (value) {
            if (value > 127) {
                return 127;
            } else if (value < 0) {
                return 0;
            } else {
                return value;
            }
        },

        preventInt8: function (value) {
            if (value > 255) {
                return 255;
            } else if (value < 0) {
                return 0;
            } else {
                return value;
            }
        },

        preventInt32: function (value) {
            if (value > 2147483647) {
                return 2147483647;
            } else if (value < 0) {
                return 0;
            } else {
                return value;
            }
        },

        preventInt64: function (value) {
            if (value > 9223372036854775807) {
                return 9223372036854775807;
            } else if (value < 0) {
                return 0;
            } else {
                return value;
            }
        },

        preventOneEiB: function (value) {
            if (value > 1152921504606846976) {
                return 1152921504606846976;
            } else if (value < 0) {
                return 0;
            } else {
                return value;
            }
        }
    };
});


// url methods here
$(function urlLegacyHelper() {
    "use strict";
    // TODO: Revise documentation for url JS namespace

    var _getUrlParameters = function (urlParams) {
        if (urlParams.indexOf('?') !== -1) {
            return $.deparam(urlParams.split('?')[1]);
        } else {
            return null;
        }
    };

    window.url = {
        /**
        * get parameters from url
        * @param {string} input url with parameters
        * @return {Object} url parameters in dictionary
        */
        getUrlParameters: _getUrlParameters,

        /**
        * parse url to url and parameters
        * @param {string} urlData - input url with parameters
        * @return {Object} url / url with parameters
        */
        parseUrl: function (urlData) {
            var params = _getUrlParameters(urlData);

            if (params) {
                return { content: urlData.split('?')[0], params: params };
            } else {
                return { content: urlData, params: {} };
            }
        },

        /**
        * get url parameter from actual browser url (actual in address bar)
        * @param {string} name - parameter name that should be extracted from url
        * @return {string} value of parameter
        */
        getUrlParameter: function (name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null
                ? ""
                : decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        /**
        * get concrete parameter from url
        * @param {string} name - parameter name
        * @param {string} defstring - url string
        * @return {string} value of parameter
        */
        getUrlParameterFromString: function (name, defstring) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(defstring);
            return results === null
                ? ""
                : decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        /**
        * set concrete parameter in url
        * @param {string} uri concrete url
        * @param {string} key - name of parameter to replace
        * @param {string} value - concrete value for replace
        * @return {string} url with updated parameter
        */
        updateUrlParameter: function (uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1
                ? "&"
                : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            } else {
                return uri + separator + key + "=" + value;
            }
        }
    };
});


// objects operations
$(function objsLegacyHelper() {
    "use strict";
    // TODO: Revise documentation for objs JS namespace

    window.objs = {
        empty: function () {
            return null;
        },

        /**
        * get dictionary data by concrete value
        * @param {object} dict - input dictionary
        * @param {string} value - concrete unique (or first) value
        * @return {string} specific key
        */
        getKeyByValue: function (dict, value) {
            var result = null;

            Object.keys(dict).forEach(function (key) {
                if (dict[key] === value) {
                    result = key;
                    return null;
                }
            });

            return result;
        }
    };
});


/**
 * OBSOLETE - Replaced with Helpers/StringOperations.ts
 */
$(function stringsLegacyHelper() {
    "use strict";
    // TODO: Revise documentation for strings JS namespace

    window.strings = {
        /**
        * formatting string e.g. strings.format(Resources.FieldXMustNotBeEmpty, that.model[key].Label)
        * @param {string} format - input data for formatting string
        * @return {string} formatted string
        */
        format: function (format) {
            var args = Array.prototype.slice.call(arguments, 1);
            return format.replace(/\{(\d+)\}/g, function (match, number) {
                return typeof (args[number] !== 'undefined') ? args[number] : match;
            });
        },

        /**
        * remove html tags from string
        * @param {string} text - input where html tags should be removed
        * @return {string} text without html tags
        */
        removeHtmlTags: function (text) {
            return text.replace(/([<\>])/g, '');
        },

        /**
        * replace some deprecated html chars
        * @param {string} text - input text where html tags should be replace
        * @return {string} result test
        */
        escapeHtml: function (text) {
            return text
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        }
    };
});


// color operations
$(function colorLegacyHelper() {
    "use strict";
    // TODO: Revise documentation for color JS namespace

    window.color = {
        /**
        * get rgba string from input data
        * @param {object} data - rgb input data
        * @param {number} alpha - value for alpha
        * @return {string} e.g. rgba(5, 10, 15, 0.5)
        */
        getRgbaString: function (data, alpha) {
            return "rgba(" + data.r.toString() + ", " + data.g.toString() + ", " + data.b.toString() + ", " + alpha.toString() + ")";
        },

        /**
        * convert hex number to rgb color object (input e.g. #00CCAA)
        * @param {string} hex - input color string
        * @return {object} rgb color data
        */
        hexToRgb: function (hex) {
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (ignore, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        }
    };
});


// general window keys monitoring
$(function modifierKeysMonitoringInit(params) {
    "use strict";

    window.keyCtrl = false;
    window.keyShift = false;

    document.onkeyup = function (event) {
        event = event || window.event;
        var e = event.keyCode;

        if (e === 17) {
            window.keyCtrl = false;
        } else if (e === 16) {
            window.keyShift = false;
        }
    };

    document.onkeydown = function (event) {
        event = event || window.event;
        var e = event.keyCode;

        if (e === 17) {
            window.keyCtrl = true;
        } else if (e === 16) {
            window.keyShift = true;
        }
    };
});
