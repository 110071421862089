
/**
 * Validation options.
 * Contains properties for validation rules and messages for those rules to be displayed if a rule is violated.
 */
export class FormValidationOptions {
    public rules: JQueryValidation.RulesDictionary;
    public messages: Map<string, Map<string, string>> | null;

    /** Element containing validation messages from server validation for concrete form fields can be defined. */
    public serverMessagesElem: JQuery | null;

    constructor() {
        this.rules = {};
        this.messages = null;
        this.serverMessagesElem = null;
    }
}
