/**
* goa - monitoring customer actions and sending to google server
*/
$(function goaLegacyModule() {
    "use strict";
    var _userId = "[not set]";

    if (window.gaEnabled) {
        (function (i, s, o, g, r, a, m) {
            i.GoogleAnalyticsObject = r;
            i[r] = i[r] || function () {
                i[r].q = i[r].q || [];
                i[r].q.push(arguments);
            };
            i[r].l = 1 * new Date();
            a = s.createElement(o);
            m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        }(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga'));

        ga('create', 'UA-72461040-1', {
            'cookieDomain': 'none'
        });
    }

    var _gaTrackPage = function () {
        if (window.gaEnabled) {
            ga('send', 'pageview', location.pathname);
        }
    };

    var _gaTrack = function (category, action) {
        if (window.gaEnabled) {
            ga('send', 'event', category, action, _userId);
        }
    };

    window.goa = {
        gaTrack: _gaTrack,
        gaTrackPage: _gaTrackPage,

        gaGridDetailExpand: function (e) {
            _gaTrack("Grid " + $(e.sender.element).parent().parent().attr("data-name"), "Detail expanded");
        },

        setUserId: function (userId) {
            _userId = userId;
        },

        createDataString: function (data) {
            var i = 0, strings = [];

            Object.keys(data).forEach(function (key) {
                if (data[key].length > 0) {
                    strings[i] = key + ": [" + data[key].join(", ") + "]";
                    i += 1;
                }
            });

            return strings.join(", ");
        }
    };
});
