
import { isUndefined } from "util";
import { UndefinedValueException } from "../Exceptions/Exceptions";
import { defaultConsoleLogger } from "./Logging/DefaultConsoleLogger";



/**
 * Static helpers for working with possibly undefined types.
 *
 * I.e.: jQuery methods' .val(), .attr(name), .width() and others' results.
 */
export abstract class IfUndefined {
    /**
     * Helper method to simplify Type | undefined to Type and throw an exception if undefined.
     *
     * @param valueToCheck Value which is possibly undefined
     * @param exceptionMessage Optional exception message. Defaults to: "Unexpected undefined value"
     *
     * @returns Original value if not undefined
     *
     * @throws UndefinedValueException
     */
    public static throwException<T>(valueToCheck: T | undefined, exceptionMessage?: string): T {
        if (isUndefined(valueToCheck)) {
            if (!exceptionMessage) exceptionMessage = "Unexpected undefined value";

            throw new UndefinedValueException(exceptionMessage);
        }

        return valueToCheck;
    }

    /**
     * Helper method to simplify Type | undefined to Type and supply default value if undefined.
     * Same as writing: isUndefined(valueToCheck) ? valueIfUndefined : valueToCheck
     *
     * @param valueToCheck Value which is possibly undefined
     * @param valueIfUndefined Value to return when checked value is undefined
     *
     * @returns Original value if not undefined, or supplied default value.
     */
    public static defaultValue<T>(valueToCheck: T | undefined, valueIfUndefined: T): T {
        return isUndefined(valueToCheck) ? valueIfUndefined : valueToCheck;
    }

    /**
     * Helper method to simplify Type | undefined to Type and supply default value if undefined.
     * Similar to DefaultValue except this will log a warning message to console when the value to check indeed is undefined.
     *
     * @param valueToCheck Value which is possibly undefined
     * @param valueIfUndefined Value to return when checked value is undefined
     * @param logMessage Optional message to log as warning. Defaults to: "Default value supplied for undefined: $valueIfUndefined"
     *
     * @returns Original value if not undefined, or supplied default value.
     */
    public static defaultValueWithWarning<T>(valueToCheck: T | undefined, valueIfUndefined: T, logMessage?: string): T {
        if (isUndefined(valueToCheck)) {
            if (!logMessage) logMessage = "Default value supplied for undefined: " + valueIfUndefined;
            defaultConsoleLogger.warn(logMessage);

            return valueIfUndefined;
        }

        return valueToCheck;
    }

}
