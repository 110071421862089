import { AjaxErrorCallback, AjaxSuccessCallback } from "./AjaxTypes";
import { RequestHelperException, RequestHelperTokenException } from "../../Exceptions/Exceptions";

import { IWebConsoleWindow } from "../../../window.globals";
import { IfUndefined } from "../UndefinedHelpers";
import { isString } from "util";

declare const window: IWebConsoleWindow;

export interface IRequestTokenObject {
    [key: string]: string;

    __RequestVerificationToken: string;
}

/**
 * Provides mainly send and formPost methods to post data to server via AJAX.
 * See members' documentation for details.
 */
export class RequestHelper {
    public readonly requestTokenName = "__RequestVerificationToken";
    private xhrPool: JQueryXHR[] = [];
    private _requestTokenValue: string;

    /**
     * Specify input element (by jQuery object) containing forgeryToken value.
     * Value is immediately extracted by calling jQuery's .val() method.
     */
    public setForgeryTokenByJQuery(elem: JQuery) {
        if (elem.length === 0) throw new RequestHelperException("Could not find request token element.");
        if (elem.length !== 1) throw new RequestHelperException("Too many request token elements: " + elem.length);

        const value = IfUndefined.defaultValue(elem.val(), "");
        this.setForgeryTokenByValue(value.toString());
    }

    /**
     * Specify forgeryToken value directly.
     */
    public setForgeryTokenByValue(value: string) {
        if (!isString(value) || !value)
            throw new RequestHelperTokenException("Invalid request token value: " + value);

        this._requestTokenValue = value;
    }

    /**
     * Gets object with anti-forgery token as the only property.
     */
    public getRequestTokenObject(): IRequestTokenObject {
        if (this._requestTokenValue)
            return { __RequestVerificationToken: this._requestTokenValue };

        throw new RequestHelperTokenException("Request token not defined.");
    }

    /**
     * Gets string with anti-forgery token.
     */
    public getRequestToken(): string {
        if (this._requestTokenValue)
            return this._requestTokenValue;

        throw new RequestHelperTokenException("Request token not defined.");
    }

    /**
     * Abort all active ajax requests.
     */
    public abortAllAjaxes(): void {
        this.xhrPool.forEach((xhr) => {
            if (xhr.readyState !== 4)
                xhr.abort();
        });
        this.xhrPool = [];
    }

    /**
     * Sends jQuery.ajax() POST request to server.
     *
     * @param urlSuffix Trailing part for URL (Controller/Action) that is prepended with WebSafetica root URL. Should not begin with slash.
     * @param data Object that will be JSON-serialised and sent to server.
     * @param onSuccess Callback function called on succesfull response. Will receive data, textStatus, jqXHR arguments. See jQuery.ajax documentation for details.
     * @param onError Callback function called on AJAX error. Will receive jqXHR, textStatus, errorThrown arguments. See jQuery.ajax documentation for details.
     * @param headers An object of additional header key/value pairs to send along with requests. See jQuery.ajax documentation for details.
     */
    public ajaxPost(
        urlSuffix: string,
        data: any,
        onSuccess?: AjaxSuccessCallback,
        onError?: AjaxErrorCallback,
        headers?: { [key: string]: any },
        skipPool?: boolean,
        contentType?: any,
        unstringify?: boolean
    ) {
        const headData = headers || {};
        if (this._requestTokenValue)
            headData[this.requestTokenName] = this._requestTokenValue;
        else
            throw new RequestHelperTokenException("Request token not defined for AJAX post to " + urlSuffix);

        const postData = !unstringify ? JSON.stringify(data) : data;

        const options: JQueryAjaxSettings = {
            cache: false,
            async: true,
            traditional: true,
            headers: headData,
            url: window.mainUrl + urlSuffix,
            type: "POST",
            contentType: contentType ?? "application/json; charset=utf-8",
            data: postData,
            processData: !unstringify,
            success: onSuccess,
            error: onError,
            beforeSend: (jqXHR: JQueryXHR) => {
                if (!skipPool) this.xhrPool.push(jqXHR);
            },
        };

        $.ajax(options);
    }

    /**
     * Simulates POSTing a form to given URL with fields defined by given data.
     *
     * @param urlSuffix Trailing part for URL (Controller/Action) that is prepended with WebSafetica root URL. Should not begin with slash.
     * @param data Object which will be used to create form with one field per attribute in the object.
     */
    public formPost(urlSuffix: string, data?: { [key: string]: any }) {
        if (!data) data = {};

        const formAction = window.mainUrl + urlSuffix;
        const formElem = $("<form action='" + formAction + "' method='POST' target='formframe' style='display: none'></form>");

        if (this._requestTokenValue && !data.hasOwnProperty(this.requestTokenName))
            formElem.append("<input type='hidden' name='" + this.requestTokenName + "' value='" + this._requestTokenValue + "' />");

        for (const fieldName in data) {
            if (data.hasOwnProperty(fieldName)) {
                const fieldValue = typeof data[fieldName] === "object" ? JSON.stringify(data[fieldName]) : data[fieldName];
                const fieldElem = $("<textarea name='" + fieldName + "'>" + fieldValue + "</textarea>");

                formElem.append(fieldElem);
            }
        }

        $("body").append(formElem);
        formElem.submit();

        setTimeout(() => { formElem.remove(); }, 0);
    }
}
