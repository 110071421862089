
import * as DetectBrowser from "detect-browser";



/**
 * 'aol' | 'edge' | 'yandexbrowser' | 'vivaldi' | 'kakaotalk' | 'samsung' | 'silk' | 'chrome'
 * | 'phantomjs' | 'crios' | 'firefox' | 'fxios' | 'opera-mini' | 'opera' | 'ie' | 'bb10'
 * | 'android' | 'ios' | 'safari' | 'facebook' | 'instagram' | 'ios-webview' | 'searchbot'
 */
declare type BrowserString = typeof DetectBrowser.BrowserInfo.name;

export const UNKNOWN_UA_LABEL = "N/A";

export class UserAgentInfo {

    /** Browser name. I.e.: "Chrome", "Firefox", "Edge", "Safari", "Internet Explorer". */
    public readonly name: string;

    /** Version number (major only) possibly decimal. I.e.: 1.2 */
    public readonly version: number;

    /** Version number (major only), never decimal. I.e.: 10 */
    public readonly versionInt: number;

    constructor(name?: BrowserString, version?: string) {
        this.name = this._normalizeName(name);
        if (version) {
            this.version = parseFloat(version);
            this.versionInt = parseInt(version);
        }
    }

    public get isUnknown(): boolean {
        return this.name === UNKNOWN_UA_LABEL;
    }

    public toString(): string {
        if (this.versionInt > 0)
            return `${this.name} ${this.versionInt}`;
        else
            return this.name;
    }

    private _normalizeName(name?: BrowserString): string {
        if (!name)
            return UNKNOWN_UA_LABEL;

        name = name.trim();
        if (name.length === 0)
            return UNKNOWN_UA_LABEL;

        name = name.toLowerCase();
        if (name === "aol") return "AOL";
        if (name === "bb10") return "BlackBerry";
        if (name === "ie" || name === "msie") return "Internet Explorer";
        if (name === "ios") return "iOS";
        if (name === "phantomjs") return "PhantomJS";
        if (name === "fxios") return "Firefox on iOS";
        if (name === "crios") return "Chrome on iOS";
        if (name === "yandexbrowser") return "Yandex Browser";
        if (name === "kakaotalk") return "KakaoTalk";
        if (name === "searchbot") return "SearchBot";

        if (name.indexOf("-") >= 0) {
            const words = name.split("-");
            const result: string[] = [];
            words.forEach((word) => {
                result.push(this._capitalize(word));
            });

            return result.join(" ");
        }

        return this._capitalize(name);
    }

    private _capitalize(word: string): string {
        word = word.toLowerCase();
        if (word === "ios") return "iOS";
        if (word === "webview") return "WebView";
        if (word.length <= 1) return word;

        return word.substr(0, 1).toUpperCase() + word.substr(1);
    }
}
