
/**
 * Provides static methods for generating identifiers.
 */
export class Identify {
    /**
     * Generates pseudo-random alpha-numeric ID, (starting with letter)
     * @param length How many characters should the ID have
     */
    public static makeId(length: number): string {
        let text = "";
        let possible = "abcdefghijklmnopqrstuvwxyz"; // start with just letters

        for (let i = 0; i < length; i += 1) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));

            if (i === 0) { possible += "0123456789"; } // after first letter, expand possibilities with numbers
        }

        return text;
    }

    /**
     * Generates pseudo-random numeric ID, (starting with non-zero)
     * @param length How many numbers should the ID have
     */
    public static makeNumId(length: number): string {
        let text = "";
        let possible = "123456789"; // start without zero

        for (let i = 0; i < length; i += 1) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));

            if (i === 0) { possible += "0"; } // after first non-zero number, expand possibilities with zero
        }

        return text;
    }

    /**
     * Generates pseudo-random ID consisting of letters only
     * @param length How many letters should the ID have
     */
    public static makeStringId(length: number): string {
        let text = "";
        const possible = "abcdefghijklmnopqrstuvwxyz";

        for (let i = 0; i < length; i += 1) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }
}
