
/** ".sft" - Root namespace. All events should have it. */
const ROOT_NS = ".sft";

const NS = {
    /** ".sft" */
    root: ROOT_NS,

    /**
     * ".sft.onceOnContentViewSwitch"
     *
     * Use together with spa.contentViewSwitch event to execute it only once on next content view switch.
     */
    onceOnContentViewSwitch: `${ROOT_NS}.onceOnContentViewSwitch`,

    /**
     * ".sft.onceOnContentBlockerFadeStarted"
     *
     * Use together with spa.contentBlockerFadeStarted event to execute it only once on next content view switch
     * and when UI blocker starts to fade out (UI is starting to be visible).
     */
    onceOnContentBlockerFadeStarted: `${ROOT_NS}.onceOnContentBlockerFadeStarted`,

    /**
     * ".sft.onceOnContentBlockerRemoved"
     *
     * Use together with spa.contentBlockerRemoved event to execute it only once on next content view switch
     * and after UI blocker gets completely removed (UI is usable for user).
     */
    onceOnContentBlockerRemoved: `${ROOT_NS}.onceOnContentBlockerRemoved`,

    /**
     * ".sft.onlyForCurrentSpaView"
     *
     * Use to bind events to window that should only by active for current SPA view and removed when loading another.
     */
    onlyForCurrentSpaView: `${ROOT_NS}.onlyForCurrentSpaView`,

    /** ".sft.spaContentScroll" */
    spaContentScroll: `${ROOT_NS}.spaContentScroll`,
};

export const WebConsoleEvents = {
    namespaces: NS,

    global: {
        /** "entrypointLoaded" - Triggered when parsing of TS entrypoint script is finished and all JS modules, constant and widgets should be ready to use.  */
        entrypointLoaded: "entrypointLoaded",
        /** "webConsoleEnumsComplete" - Triggered when enums serialized via Html.SerializeEnumeration* helpers are defined. */
        webConsoleEnumsComplete: "webConsoleEnumsComplete",
    },

    spa: {
        /** "spaRoutesDefined" - Triggered when routeManager parses JSON with routes and is ready to be used. */
        routesDefined: "spaRoutesDefined",
        /** "spaDatePickerReady" - Triggered on end of _init() method of SpaDatePicker. */
        datePickerReady: "spaDatePickerReady",
        /**
         * "beforeCurrentViewLeave"
         *
         * Triggered before leaving the current view. Takes a callback function with a parameter that specifies whether the view switching process can continue.
         * Every callback call with "true" as a parameter triggers separate content reload action so use it only once with proper NS. Calling the callback function is mandatory.
         */
        beforeCurrentViewLeave: "beforeCurrentViewLeave",
        /** "beforeContentViewSwitch" - Triggered before a new view HTML is requested (with NS-onceOnContentViewSwitch could be used as destructor for current view) */
        beforeContentViewSwitch: "beforeContentViewSwitch",
        /** "contentViewSwitch" - Triggered when spaManager loads new view HTML. */
        contentViewSwitch: "contentViewSwitch",
        /** "contentBlockerFadeStarted" - Triggered when SPA content blocker has started fading out (after spaManager loads new view HTML). */
        contentBlockerFadeStarted: "contentBlockerFadeStarted",
        /** "contentBlockerRemoved" - Triggered when SPA content blocker has been fully removed (after spaManager loads new view HTML). */
        contentBlockerRemoved: "contentBlockerRemoved",
        /** "contentResize" - Triggered when SpaContentSizer resizes the content block. Use to bind listeners for inner elements that might need to resize as well. */
        contentResize: "contentResize",
    },
    commonView: {
        /** "commonViewFilterChange" */
        filterChange: "commonViewFilterChange",
        /** "commonViewContentUpdate" */
        contentUpdate: "commonViewContentUpdate",
    },

    management: {
        /** "officeControlAutoScrollAndEditSettings" */
        officeControlAutoScrollAndEditSettings: "officeControlAutoScrollAndEditSettings",
    },

    mobileRules: {
        /** "androidAppProductSelectAction" */
        androidAppProductSelectAction: "androidAppProductSelectAction",
        /** "androidAppProductRemoveAction" */
        androidAppProductRemoveAction: "androidAppProductRemoveAction",
    },
};
