
export class Exception extends Error {

    /**
     * Promotes Error instance to ExceptionBase instance.
     * @param error VanillaJS Error object instance
     */
    public static PromoteErrorToException(error: Error): Exception {
        return new Exception(error.message);
    }

    public message: string;
    public innerException: Exception;

    constructor(msg: string, innerException?: Exception) {
        super(msg);
        this.message = msg;

        if (innerException instanceof Exception)
            this.innerException = innerException;
    }

    public toString(): string {
        return this.name +  ": " + this.message;
    }

}
